<template>
  <div class="nav-menu">
    <div
      v-for="category in allowedMenuCategories"
      :key="category.title"
      class="nav-menu__category"
    >
      <span class="category__title">
        {{ $t(category.title) }}
      </span>

      <div class="category__items">
        <template
          v-for="item in category.items"
          :key="item.path"
        >
          <ReflectLink
            v-if="item.shouldDisplay"
            class="category__item"
            :link="item.path"
          >
            <span>
              {{ $t(item.text) }}
            </span>
          </ReflectLink>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ROUTE } from '~/constants/routes'
import { hasUserAccessToPage } from '~/services/router'
import { useCurrentUserStore } from '~/stores/currentUser'

export default {
  name: 'NavMenu',
  computed: {
    user() {
      return useCurrentUserStore()
    },
    allowedMenuCategories() {
      return this.menuItems.filter(category =>
        category.items.some(item => item.shouldDisplay)
      )
    },
    menuItems() {
      return [
        {
          title: 'settings.usersPermissions.title',
          items: [
            {
              text: 'settings.usersPermissions.users.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__PERMISSIONS__USERS)
            },
            {
              text: 'settings.usersPermissions.accessRoles.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__PERMISSIONS__ROLES)
            },
            {
              text: 'settings.usersPermissions.dataSensitivity.title',
              ...this.setPathAndDisplay(
                ROUTE.SETTINGS__PERMISSIONS__SENSITIVITY
              )
            },
            {
              text: 'settings.usersPermissions.sourceOwnership.title',
              ...this.setPathAndDisplay(
                ROUTE.SETTINGS__PERMISSIONS__SOURCE_OWNERSHIP
              )
            }
          ]
        },
        {
          title: 'settings.integrations.title',
          items: [
            {
              text: 'settings.integrations.dataSources.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__INTEGRATIONS__SOURCES)
            },
            {
              text: 'settings.dataMapping.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__INTEGRATIONS__MAPPING)
            },
            {
              text: 'settings.dataNormalization.title',
              ...this.setPathAndDisplay(
                ROUTE.SETTINGS__INTEGRATIONS__NORMALIZATION
              )
            },
            {
              text: 'settings.integrations.dataHealth.title',
              ...this.setPathAndDisplay(
                ROUTE.SETTINGS__INTEGRATIONS__DATA_HEALTH
              )
            }
          ]
        },
        {
          title: 'settings.workspace.title',
          items: [
            {
              text: 'settings.workspace.preferences.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__WORKSPACE__PREFERENCES)
            },
            {
              text: 'settings.workspace.renaming.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__WORKSPACE__RENAMING)
            }
          ]
        },
        {
          title: 'settings.myAccount.title',
          items: [
            {
              text: 'settings.myAccount.profile.login.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__MY_ACCOUNT__PROFILE)
            },
            {
              text: 'settings.myAccount.preferences.language.title',
              ...this.setPathAndDisplay(ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES)
            }
          ]
        }
      ]
    }
  },
  methods: {
    setPathAndDisplay(route: ROUTE) {
      return {
        path: route,
        shouldDisplay: hasUserAccessToPage(this.user, route)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px;
  @include block-shadow;
  background-color: $bg-secondary;

  &__category {
    display: flex;
    flex-direction: column;
    padding: $margin-regular $margin-intermediate;
    border-bottom: 1px solid $border-ternary;
    cursor: default;

    .category {
      &__title {
        @include font-text($font-weight-medium);
        @include font-size($font-size-regular);
        color: $text-secondary;
        margin-bottom: 6px;
      }

      &__item {
        padding: 6px 0 6px $margin-small;
        @include font-text;
        @include font-size($font-size-small);
        border-radius: 4px;
        cursor: pointer;

        &.router-link-exact-active {
          background-color: $bg-quinary;
        }
      }
    }
  }
}
</style>
